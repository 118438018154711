import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CourseCodeFormViewModel } from '../../types/CourseCodeFormViewModel';
import { CourseCodeListItemModel } from '../../types/CourseCodeListItemModel';
import { CourseCodeModel } from '../../types/CourseCodeModel';
import { DataEntryMode } from '../../types/DataEntryMode';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchCourseCodeById,
  fetchCourseCodeFormViewModel,
  fetchCourseCodeListItems,
} from '../thunks/courseCodeThunks';

interface CourseCodeState {
  loadingListItems: boolean;
  listItems: CourseCodeListItemModel[];

  loadingCourseCode: boolean;
  courseCode: CourseCodeModel;

  loadingFormViewModel: boolean;
  formViewModel: CourseCodeFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultListState: CourseCodeListItemModel[] = [];

export const defaultCourseCodeState: CourseCodeModel = {
  courseTitle: '',
  courseCode: '',
  courseDepartmentName: '',
  courseDescription: '',
  courseAlignedWithStandards: false,
  assessmentIdentifier: false,
  courseApplicableEducationLevel: [],
  availableCarnegieUnitCredit: [],
  courseLevelCharacteristic: '',
  workBasedLearningOpportunityType: '',
  sequenceOfCourse: '',
  cipCode: '',
  careerCluster: '',
  courseCertificationDescription: '',
  coreAcademicCourse: false,
  certificateRequired: '',
  specificEndorsements: [],
  courseFundingProgram: [],
  maepSelectable: false,
  scedCourseCode: '',
  scedCourseLevel: '',
  scedCourseSubjectArea: '',
  scedGradeSpan: '',
  scedSequenceOfCourse: '',
  courseBeginDate: '',
  courseEndDate: '',
};

export const defaultDataEntryMode = DataEntryMode.NEW;

export const defaultFormState: CourseCodeFormViewModel = {
  courseApplicableEducationLevelOptions: [],
  availableCarnegieUnitCreditOptions: [],
  workBasedLearningOpportunityTypeOptions: [],
  sequenceOfCourseOptions: [],
  cipCodeOptions: [],
  careerClusterOptions: [],
  certificateRequiredOptions: [],
  specificEndorsementOptions: [],
  courseFundingProgramOptions: [],
  courseLevelCharacteristicOptions: [],
  scedCourseLevelOptions: [],
  scedCourseSubjectAreaOptions: [],
  courseBeginDate: '',
  courseEndDate: '',
};

export const initialState: CourseCodeState = {
  loadingListItems: false,
  listItems: defaultListState,
  loadingCourseCode: false,
  courseCode: defaultCourseCodeState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: defaultDataEntryMode,
};

export const courseCodeSlice = createSlice({
  name: 'courseCode',
  initialState,
  reducers: {
    clearCourseCode: (state) => {
      state.courseCode = defaultCourseCodeState;
    },
    saveCourseCode: (state, action: PayloadAction<CourseCodeModel>) => {
      state.courseCode = action.payload;
    },
    setCourseCodeDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchCourseCodeList
    builder.addCase(fetchCourseCodeListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(fetchCourseCodeListItems.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.listItems = action.payload;
      }
      state.loadingListItems = false;
    });
    builder.addCase(fetchCourseCodeListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchCourseCodeById
    builder.addCase(fetchCourseCodeById.pending, (state) => {
      state.courseCode = defaultCourseCodeState;
      state.loadingCourseCode = true;
    });
    builder.addCase(fetchCourseCodeById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.courseCode = action.payload;
      }
      state.loadingCourseCode = false;
    });
    builder.addCase(fetchCourseCodeById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingCourseCode = false;
      }
    });

    // fetchCourseCodeFormViewModel
    builder.addCase(fetchCourseCodeFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(fetchCourseCodeFormViewModel.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.formViewModel = action.payload;
      }
      state.loadingFormViewModel = false;
    });
    builder.addCase(fetchCourseCodeFormViewModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingFormViewModel = false;
      }
    });
  },
});

export const { clearCourseCode, saveCourseCode, setCourseCodeDataEntryMode } =
  courseCodeSlice.actions;

// listItems
export const selectCourseCodeLoadingListItems = (state: RootState): boolean =>
  state.courseCode.loadingListItems;
export const selectCourseCodeListItems = (
  state: RootState
): CourseCodeListItemModel[] => state.courseCode.listItems;

// courseCode
export const selectCourseCodeLoadingCourseCode = (state: RootState): boolean =>
  state.courseCode.loadingCourseCode;
export const selectCourseCodeCourseCode = (state: RootState): CourseCodeModel =>
  state.courseCode.courseCode;

// formViewModel
export const selectCourseCodeLoadingFormViewModel = (
  state: RootState
): boolean => state.courseCode.loadingFormViewModel;
export const selectCourseCodeFormViewModel = (
  state: RootState
): CourseCodeFormViewModel => state.courseCode.formViewModel;

// dataEntryMode
export const selectCourseCodeDataEntryMode = (
  state: RootState
): DataEntryMode => state.courseCode.dataEntryMode;

export default courseCodeSlice.reducer;
