import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { appRoutePaths } from '../../../services/route.service';
import { canUserAccessRoute } from '../../../services/security.service';
import FailureCard from '../FailureCard/FailureCard';
import { selectSelectedDistrict } from '../../../redux/slices/appSlice';
import {
  selectLeasWithFileFailures,
} from '../../../redux/slices/dashboardSlice';
 

const FailedFilesCard = (): ReactElement => {
  const leasWithFileFailures: Array<string> = useAppSelector(selectLeasWithFileFailures);
  const { districtId } = useAppSelector(selectSelectedDistrict);

  if (districtId == null || !leasWithFileFailures.includes(districtId)) {
    return <></>;
  } else {
    return (
      <FailureCard
        message={'You have files in failed status'}
        buttonText={'View Submission Status'}
        buttonLink={appRoutePaths.SubmissionStatus}
        buttonVisible={canUserAccessRoute(appRoutePaths.SubmissionStatus)}
        tooltipText={'Click to review errors'}
      />
    );
  }
};

export default FailedFilesCard;
