/* eslint-disable max-len */
import { ReactElement } from 'react';
import FormCheckbox from '../../../../components/FormInputs/FormCheckbox/FormCheckbox';
import FormCheckboxList from '../../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDateSelector from '../../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../../components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../../components/FormInputs/FormRadioList/FormRadioList';
import FormTextarea from '../../../../components/FormInputs/FormTextarea/FormTextarea';
import FormTextbox from '../../../../components/FormInputs/FormTextbox/FormTextbox';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import Panel from '../../../../components/Panel/Panel';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearCourseCode,
  saveCourseCode,
  selectCourseCodeCourseCode,
  selectCourseCodeDataEntryMode,
  selectCourseCodeFormViewModel,
  selectCourseCodeLoadingCourseCode,
  selectCourseCodeLoadingFormViewModel,
  setCourseCodeDataEntryMode,
} from '../../../../redux/slices/courseCodeSlice';
import {
  fetchCourseCodeById,
  fetchCourseCodeFormViewModel,
} from '../../../../redux/thunks/courseCodeThunks';
import {
  createCourseCode,
  updateCourseCode,
} from '../../../../services/courseCode.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { CourseCodeFormViewModel } from '../../../../types/CourseCodeFormViewModel';
import { CourseCodeModel } from '../../../../types/CourseCodeModel';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_MDE_OTSS } from '../../../../types/NavigationTabs';
import { PanelType } from '../../../../types/PanelType';
import { FormRadioListOrientation } from '../../../../types/propTypes/FormRadioListPropTypes';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { CourseCodeValidationSchema } from '../../../../types/Validation/CourseCodeValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import { isStateAdmin } from '../../../../utilities/userUtilities';
import './CourseCodeInput.css';

const CourseCodeInput = (): ReactElement => {
  const courseCodeModel: CourseCodeModel = useAppSelector(
    selectCourseCodeCourseCode
  );
  const formViewModel: CourseCodeFormViewModel = useAppSelector(
    selectCourseCodeFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectCourseCodeLoadingFormViewModel
  );
  const isLoadingCourseCodeModel = useAppSelector(
    selectCourseCodeLoadingCourseCode
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectCourseCodeDataEntryMode
  );
  const isLoading = isLoadingCourseCodeModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
    },
  ];

  return (
    <ManualInputPage<CourseCodeModel, CourseCodeFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'course-code-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_MDE_OTSS)}
      classes={{ pageClass: 'data-input--course-codes--input' }}
      parameterOptions={{
        routeParameterName: 'courseCodeId',
        identityName: courseCodeModel.courseCode,
      }}
      routes={{
        backBarRoute: appRoutePaths.CourseCodeList,
        cancelRoute: appRoutePaths.CourseCodeList,
        createSuccessRoute: appRoutePaths.CourseCodeInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchCourseCodeById,
        loadFormDetail: fetchCourseCodeFormViewModel,
        setEntryMode: setCourseCodeDataEntryMode,
        setData: saveCourseCode,
        clearData: clearCourseCode,
        onCreate: createCourseCode,
        onUpdate: updateCourseCode,
      }}
      formDetails={{
        validationSchema: CourseCodeValidationSchema,
        initialFormValues: courseCodeModel,
        isFormModelEmpty:
          !formViewModel.courseApplicableEducationLevelOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'course code',
        pageHeader: 'MDE/OTSS',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="course-code-input-child">
        <Panel panelType={PanelType.INFO} heading="Course Code Information">
          <div className="field-row field-row-grid-1-3-2">
            <FormTextbox
              displayName="Course Code"
              field="courseCode"
              validationSchema={CourseCodeValidationSchema}
              disabled={
                !isStateAdmin()
                  ? true
                  : dataEntryMode === DataEntryMode.EDIT
                  ? true
                  : false
              }
            />
            <FormTextbox
              displayName="Course Title"
              field="courseTitle"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormTextbox
              displayName="Course Department Name"
              field="courseDepartmentName"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormTextarea
              displayName="Course Description"
              field="courseDescription"
              rows={3}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormCheckbox
              displayName="Course Aligned with MS CCR Standards?"
              field="courseAlignedWithStandards"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormCheckbox
              displayName="End of Course Assessment Requirement?"
              field="assessmentIdentifier"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row field-row-grid-2-1-1">
            <FormCheckboxList
              displayName="Grades"
              field="courseApplicableEducationLevel"
              options={formViewModel.courseApplicableEducationLevelOptions}
              addAllCheckbox={true}
              classConfig={{ containerClasses: 'grades-container' }}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormDateSelector
              displayName="Course Begin Date"
              field="courseBeginDate"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormDateSelector
              displayName="Course Inactive Date"
              field="courseEndDate"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row field-row-grid-2-1-1">
            <FormDropdown
              displayName="Course Level Characteristics"
              field="courseLevelCharacteristic"
              options={formViewModel.courseLevelCharacteristicOptions}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormCheckboxList
              displayName="Carnegie Units"
              field="availableCarnegieUnitCredit"
              options={formViewModel.availableCarnegieUnitCreditOptions}
              classConfig={{ containerClasses: 'carnegie-units-container' }}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row">
            <FormCheckbox
              displayName="Core Subject?"
              field="coreAcademicCourse"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row field-row-grid-2-1">
            <FormRadioList
              displayName="SCED Course Level"
              field="scedCourseLevel"
              options={formViewModel.scedCourseLevelOptions}
              classConfig={{ containerClasses: 'sced-course-level-container' }}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row field-row-grid-1-1-1">
            <FormDropdown
              displayName="SCED Course Subject Area"
              field="scedCourseSubjectArea"
              options={formViewModel.scedCourseSubjectAreaOptions}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="CTE Only">
          <div className="field-row">
            <FormRadioList
              displayName="Year Indicator"
              field="sequenceOfCourse"
              options={formViewModel.sequenceOfCourseOptions}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>

          <div className="field-row field-row-grid-1-1">
            <FormDropdown
              displayName="CIP Code"
              field="cipCode"
              options={formViewModel.cipCodeOptions}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
        </Panel>

        <Panel panelType={PanelType.INFO} heading="Certification & Funding">
          <div className="field-row field-row-grid-1-3">
            <FormRadioList
              displayName="License Required"
              field="certificateRequired"
              options={formViewModel.certificateRequiredOptions}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
            <FormDropdown
              displayName="Specific Endorsements"
              field="specificEndorsements"
              isMulti={true}
              options={formViewModel.specificEndorsementOptions.map(
                (option) => ({
                  ...option,
                  text: `${option.value} ${option.text}`,
                })
              )}
              containerClasses="specific-endorsement-container"
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
          <div className="field-row">
            <FormCheckboxList
              displayName="Funding Codes"
              field="courseFundingProgram"
              options={formViewModel.courseFundingProgramOptions}
              classConfig={{ containerClasses: 'funding-codes-container' }}
              validationSchema={CourseCodeValidationSchema}
              disabled={!isStateAdmin()}
            />
          </div>
        </Panel>
      </div>
    </ManualInputPage>
  );
};

export default CourseCodeInput;
